<template>
<!-- eslint-disable-next-line max-len -->
<v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotDtl" :loading="loading">
  <v-card>
    <v-card-title>
      <!-- title -->
      <slot name="title">
        <div class="btn-area d-block">
          <!-- 뒤로 button -->
          <v-btn outlined rounded
            color="#fff"
            @click="goBack()"
            class="px-4"
          >
            <v-icon class="mr-1 white--text f12"> icon-hw_return </v-icon>
            뒤로
          </v-btn>
          <!-- 나의 응모 현황 button: 1. 블록체인 기반 사내추첨 리스트의 응모한 추첨 탭으로 이동 -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList','winnerList')"
            class="ml-2 px-4"
          >
            <v-icon class="mr-1 f12"> icon-hw_fb72 </v-icon>
            나의 응모 현황
          </v-btn>
        </div>
        <div class="mt-6 d-flex align-items-center justify-space-between">
          <strong class="lot-title pt-2 ellipsis">
            <!-- index 페이지에서 팝업 오픈일 때 dummy -->
            {{editedItem.eventNm === undefined ? dummy.eventNm : editedItem.eventNm}}
          </strong>
          <div class="lot-dates pl-10 f18">응모기간 :
            {{ editedItem.applyFrom === undefined ? dummy.sttDate : editedItem.applyFrom }}
            ~
            {{ editedItem.applyTo === undefined ? dummy.endDate : editedItem.applyTo }}
          </div>
        </div>
      </slot>

      <v-spacer></v-spacer>

      <v-btn v-if="showClose" @click="close()" icon>
        <v-icon class="color_grey_1--text f16">icon-hw_close</v-icon>
      </v-btn>

    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot name="content">
        <v-card flat>

          <v-card-title class="pa-0 pt-6 pb-3 align-content-end justify-space-between f13">
            <div class="d-flex">
              <div class="h-count">
                <strong>
                  총 <span>{{ lists.length }}</span>
                </strong>
                건
              </div>
            </div>
          </v-card-title>

          <v-card-text class="pa-0">
            <vuescroll class="fixed-header-table">
              <div style="height: 400px;">
                <v-data-table
                    fixed-header
                    hide-default-footer
                    ref="vDataTable"
                    :headers="headers"
                    :items="lists"
                    item-key="itemId"
                    :item-class="rowClasses"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    :page.sync="page"
                    :items-per-page="perPage"
                    @click:row="handleClick"
                    @page-count="pageCount = $event"
                    class="elevation-1 h-bc-expd-table f14"
                  >
                  <template v-slot:no-data>
                    <v-card flat class="grey--text">
                      응모가 종료되었습니다.<br/><br/>이벤트 담당자가 추첨 결과 게시 전입니다.
                    </v-card>
                  </template>
                  <!-- 이벤트 상세명 -->
                  <template #[`item.itemNm`]="{ item }">
                    <v-chip @click="detailInfo(item)"
                      small color="#d4dbff"
                      text-color="#10207d"
                      :disabled="item.detailInfo === '' && item.files.length === 0"
                      class="mr-4 font-weight-light"> 상세설명 </v-chip>
                    <span class="font-weight-medium">{{ item.itemNm }}</span>
                  </template>
                  <!-- 참여토큰 -->
                  <template #[`item.token`]="{ item }" >
                    <div v-if="userApplyItem.length !== 0">
                    <!-- {{item.applicantToken}}/{{item.tokenSum}} -->
                    {{ userApplyItem[0].itemId === item.itemId ? userApplyItem[0].applicantToken : '0' }}/{{item.tokenSum}}
                    </div>
                    <div v-else>
                      0/{{item.tokenSum}}
                    </div>
                  </template>
                  <template #[`item.winnerNum`]="{ item }">
                    {{item.winners.length}}
                  </template>
                  <!-- 당첨자 winners -->
                  <template #[`item.winners`]="{ item }">
                    <ul v-if="item.winners.length > 1" class="dcsn-member py-2 pl-0 d-flex align-items-center f12">
                      <li v-for="(member, k) in members(item.winners)" :key="k"
                        class="mr-n4" style="list-style: none; padding-left: 0px;"
                      >
                        <v-avatar color="grey lighten-4" size="40">
                          <img src="@/assets/images/img-memberS.png" :alt="member.empNm"
                          >
                        </v-avatar>
                      </li>
                      <li>
                        <v-avatar v-if="remainMemberCount(item.winners)"
                          size="40"
                          color="blue lighten-5 cursor-pointer"
                        >
                          <span class="primary--text">{{ remainMemberCount(item.winners) }}</span>
                        </v-avatar>
                      </li>
                    </ul>
                    <!-- 당첨자가 1명일 때 ? -->
                    <div v-else class="py-3 d-flex align-items-center f13">
                      <!-- 사원정보: 1. 표시될 정확한 정보 필요 -->
                      <v-avatar size="40" color="grey lighten-5" class="f11">
                        <v-img
                          src="@/assets/images/img-memberS.png"
                          :title="item.winners[0].userId === '' ? dummy.winners[0].userId : item.winners[0].empEngNm"
                        />
                      </v-avatar>
                      <div class="emp-info ml-2 text-left line-height-13">
                        <strong class="d-flex black--text">
                          <span class="ellipsis" :title="item.winners[0].userId" style="max-width: 150px;">
                            {{ item.winners[0].userId === '' ? dummy.winners[0].userId : item.winners[0].userId }}
                          </span>
                          <!-- 직책 -->
                          <span class="ml-1">{{ item.winners[0].positionNm }}</span>
                        </strong>
                        <span class="f12">{{ item.winners[0].deptNm }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- expand -->
                  <template #[`item.data-table-expand`]="props">
                    <!-- 당첨자가 1명 이상일 때 만 expand -->
                    <div v-if="props.item.winners.length > 1" class="btn-outlined rounded-xl">
                      <!--
                        {{props.isExpanded && transitioned[getItemId(props.item)]}} -->
                      <v-icon
                        :class="{
                                'v-data-table__expand-icon': true,
                                'v-data-table__expand-icon--active': transitioned[getItemId(props.item)]
                                }"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </div>
                  </template>

                  <!-- sub depth table -->
                  <template #expanded-item="{headers, item}">
                    <td
                      :colspan="headers.length"
                      :class="{
                        'ma-0': true,
                        'expanded-closing': !transitioned[getItemId(item)],
                      }"
                      style="height: auto;"
                    >
                      <v-expand-transition>
                        <div v-show="transitioned[getItemId(item)]" class="py-1 px-5">
                          <ul class="pl-0 f12" style="list-style-type: none">
                            <li v-for="(member, sk) in item.winners" :key="`${'member-'+sk}`">
                              <div class="py-3 d-flex align-items-center f13">
                              <!-- 사원정보: 1. 표시될 정확한 정보 필요 -->
                                <v-avatar size="40" color="grey lighten-5" class="f11">
                                  <v-img
                                    src="@/assets/images/img-memberS.png"
                                    :title="member.userId !== '' ? member.userId : member.userId"
                                  />
                                </v-avatar>
                                <div class="emp-info ml-1 text-left line-height-13">
                                  <strong class="d-flex black--text">
                                    <span class="ellipsis f11" :title="member.empNm" style="max-width: 150px;">
                                      {{ member.userId !== '' ? member.userId : member.userId }}
                                    </span>
                                    <!-- 직책 -->
                                    <span class="ml-1 f11">{{ member.positionNm }}</span>
                                  </strong>
                                  <span class="f12">{{ member.deptNm }}</span>
                                </div>
                              </div>
                            </li>
                          </ul>

                        </div>
                      </v-expand-transition>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </vuescroll>

            <!-- pagination -->
            <div class="h-pagination pt-7 d-flex align-items-center justify-center f14">
<!--              <strong>{{ page }}</strong > / {{ Math.ceil(lists.length / perPage) }}-->
              <v-pagination
                v-if="Math.ceil(lists.length / perPage) > 1"
                v-model="page"
                :length="Math.ceil(lists.length / perPage)"
                class="pl-2 d-flex"
              ></v-pagination>
            </div>

          </v-card-text>
        </v-card>
      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer">
        <v-spacer></v-spacer>
        <v-btn
          outlined rounded color="#ccc"
          @click="close()"
          class="px-4"
        >
          <v-icon class="mr-1 black--text f12"> icon-m-ic-close </v-icon>
          닫기
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { api, abortBearer } from '@/axios';

export default {
  name: 'DialogDayCareLotWinners',
  components: {
  },
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
  },
  data() {
    return {
      editItem: this.editedItem,
      dialog: false,
      // table
      page: 1,
      perPage: 5,
      pageCount: 0,

      pageList: [],
      userApplyItem: [],

      expanded: [],
      transitioned: [],
      closeTimeouts: {},
      singleExpand: true,
      headers: [
        { text: '상세 이벤트 명', align: 'left', value: 'itemNm', sortable: false, class: 'text-center h-event-title' },
        { text: '(자녀)응모인원', align: 'center', value: 'applicantNum', sortable: false, width: '12%', class: 'text-center' },
        { text: '참여토큰', align: 'center', value: 'token', sortable: false, width: '12%', class: 'text-center' },
        { text: '당첨인원', align: 'center', value: 'winnerNum', sortable: false, width: '12%' },
        { text: '당첨자', align: 'center', value: 'winners', sortable: false, class: 'h-winner' },
        { text: '', value: 'data-table-expand', align: 'center', sortable: false, width: '90px' },
      ],
      lists: [],
      detailInfoList: [],
      dummy: {
        evtDtlId: 'ed-10',
        applNum: '100',
        rmnToken: '10',
        stdToken: '200',
        winnerNum: '1',
        sttDate: '22.04.16',
        endDate: '22.05.15',
        applyCd: '10',
        iApplYn: false,
        eventNm: '한화와 함께하는 2022 교향악 축제',
        winners: [
          { empNm: '김옥련', avatar: '@/assets/images/img-memberS.png', orgNm: '인사팀', rspofcNm: '사원' },
          { empNm: '이선호', avatar: '@/assets/images/img-memberS.png', orgNm: '유화서비스사업 개발팀', rspofcNm: '과장' },
          { empNm: '박래원', avatar: '@/assets/images/img-memberS.png', orgNm: '차세대 ERP TF', rspofcNm: '사원' },
        ],
      },

      remainCount: 4,

    };
  },
  computed: {
    // pages() {
    //   if (this.perPage == null || this.pageCount == null) return 0;
    //   return Math.ceil(this.lists / this.perPage);
    // },
    show: {
      get() {
        // this.fetchData();
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  filters: {
  },
  mounted() {
  },
  watch: {
    singleExpand(v) {
      if (!v) return;
      // Single expand enabled. Hide all but the first expanded item
      this.expanded.forEach((item, i) => {
        if (i > 0) this.closeExpand(item);
      });
    },
    show(newVal) {
      if (newVal) {
        this.fetchData();
      }
    },
  },
  created() {
  },
  methods: {
    async fetchData() {
      const item = this.editedItem;
      if (item.eventId !== 'undefined' && item.eventId !== undefined && item.notiYn === 'Y') {
        // await this.$axios.get(`/apply/getlist/${item.eventId}`).then((res) => {
        await api.get(`/event/daycare-apply/getlist/${item.eventId}`).then((res) => {
          // console.log(res.data);
          this.lists = res.data;
          for (let i = 0; i < this.lists.length; i++) {
            this.fetchAppliedData(this.lists[i]);
          }
        });
        // await this.$axios.post('/apply/apply-item',
        await api.post('/event/eventitem/get/items', { eventId: item.eventId, userId: localStorage.getItem('userId') }).then((res) => {
          this.detailInfoList = res.data;
          console.log(this.detailInfoList);
        });
      }
    },
    async fetchAppliedData(event) {
      const controller = abortBearer.newAbortController();
      await api.post('/event/apply/apply-item',
        { eventId: event.eventId,
          userId: localStorage.getItem('userId'),
        // }).then((res) => {
        }, { signal: controller.signal }).then((res) => {
        this.userApplyItem = res.data;
        // console.log(this.userApplyItem);
        // console.log(res.data);
      }).catch((e) => {
        console.log(e);
      }).finally((e) => {
        console.log('finally', e);
        abortBearer.removeController(controller);
      });
    },
    rowClasses(item) {
      // 1. 내가 응모한 이벤트에 class 추가
      for (let i = 0; i < this.detailInfoList.length; i++) {
        if (this.detailInfoList[i].itemId === item.itemId) {
          item.detailInfo = this.detailInfoList[i].detailInfo;
          item.files = this.detailInfoList[i].files;
          item.fileId = this.detailInfoList[i].fileId;
          break;
        }
      }
      if (this.userApplyItem.length !== 0) {
        if (this.userApplyItem[0].itemId === item.itemId) {
          return 'h-apply-evt';
        }
      }
      // if (item.iApplYn === true) {
      //   return 'h-apply-evt';
      // }
      return false;
    },
    getItemId(item) {
      return item.evtDtlId; // Must be uid of record (would be nice if v-data-table exposed a "getItemKey" method)
    },
    toggleExpand(props) {
      // eslint-disable-next-line
      const item = props.item;
      const id = this.getItemId(item);

      if (props.isExpanded && this.transitioned[id]) {
        // If we're expanded and not in the process of closing, close
        this.closeExpand(item);
      } else {
        // If we're closed or in the process of closing, expand
        // Stop us from closing if a close transition was started
        // clearTimeout(this.closeTimeouts[id]);
        // Tell v-data-table to add the expansion content for the item
        props.expand(true);
        // Show expansion content with transition animation after it's had time to get added to the DOM
        this.$nextTick(() => this.$set(this.transitioned, id, true));
        // Hide all other expanded items if single-expand;
        if (this.singleExpand) this.$nextTick(() => this.expanded.forEach((i) => i !== item && this.closeExpand(i)));
      }
    },
    closeExpand(item) {
      const id = this.getItemId(item);
      // Mark that this item is in the process of closing
      this.$set(this.transitioned, id, false);
      // Remove expansion content from DOM after transition animation has had enough time to finish
      // this.closeTimeouts[id] = setTimeout(() => this.$refs.vDataTable.expand(item, false), 600);
    },
    handleClick(row, item) {
      // console.log('rows: ', row);
      this.$set(item, 'isSelected', true);

      if (row.winners.length > 1) {
        this.toggleExpand(item);
      }
    },
    imageUrlAlt(event) {
      event.target.src = '@/assets/images/img-memberS.png';
    },
    members(item) {
      return item.length > this.remainCount ? item.slice(0, this.remainCount) : item;
    },
    remainMemberCount(item) {
      let sign = '+';
      if (item.active) sign = '-';
      return item.length - this.remainCount > 0 ? `${sign}${item.length - this.remainCount}` : 0;
    },
    moreAvatar(item) {
      if (item.active) item.active = false;
      else item.active = true;
    },
    opener(name, item) {
      // 임시 : modal 내 페이지 전환 필요
      this.show = false;
      this.lists = [];
      this.$emit('openModal', name, item);
    },
    save() {
      this.show = false;
      this.lists = [];
    },
    close() {
      abortBearer.abort();
      this.show = false;
      this.lists = [];
    },
    crfunction(detailInfo) {
      return detailInfo.split('\n').join('<br />');
    },
    detailInfo(item) {
      let info = '';
      if (item.detailInfo === '') {
        info = '상세설명이 없습니다.';
      } else {
        info = this.crfunction(item.detailInfo);
      }
      for (let i = 0; i < item.files.length; i++) {
        info += `<br /><img src=/img${item.files[i].filePath} width=100 height=100 onclick='window.open(this.src)' />`;
      }
      this.$dialog.info({
        text: `${info}`,
        title: ' 상세설명 ',
      });
    },
    goBack() {
      // abortBearer.abort();
      // controller.signal.aborted = true;
      // this.controller.abort();
      abortBearer.abort();
      this.opener('openLotList');
    },
  },
};
</script>
